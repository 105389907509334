<template>
  <div class="insider-tips">
    <div class="container">
      <div class="row">
        <div v-for="item in insiderTips" :key="item.id" class="col-sm-6 col-lg-4">
          <DestinationInsiderTipItem :item="item" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DestinationInsiderTipItem from '@/components/destination/DestinationInsiderTipItem';

export default {
  name: 'DestinationInsiderTipTab',
  components: { DestinationInsiderTipItem },
  props: {
    insiderTips: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.insider-tips {
  margin-top: 15px;
  padding-bottom: 70px;

  @media (min-width: 768px) {
    margin-top: 70px;
  }
}
</style>
